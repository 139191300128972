import React from "react";
import type { HeadFC } from "gatsby";
import { Seo, Layout, PostBox } from '../components';

const IndexPage = () => {
  return (
    <Layout withCarousel>
      <PostBox title="Welcome!">
        <p>
          Hello, my name is Alese, and this is my chaotic world of art and imagination. I love
          putting my work up in coffee shops and galleries, traveling to various comic conventions,
          and collaborating with fellow creatives. Feel free to email me if you're interested
          in my work.
        </p>
      </PostBox>
    </Layout>
  )
}

export default IndexPage

export const Head: HeadFC = () => (<Seo />);
